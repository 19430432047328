
li {
    margin-bottom: 0;
}
body {
    background: url(//cdn.shopify.com/s/files/1/0985/6032/t/2/assets/body-bg.jpg?1560026016412506555) repeat 0 0 #fff !important
}

.avbar-default { background:#fff; border:none; margin-bottom:0; font-weight:500;}
.call { float:left;}

.call .image { float:left; margin:15px;}
.call .txt { float:left;}
.call h1 { font-size:24px; display:block; font-weight:normal;}
.call h1 span { font-size:14px; display:block;}
.call .mail { display:block;}
.call i { float:left; margin:3px 25px 0 20px;}
.shopp { padding-top:20px; float:left; margin-left:20px;} 
.shopp .input-group-addon{ border:none; border-radius:0; background:#D83138; height:48px; width:42px; text-align:center;} 
.shopp .form-control { border:none; border-radius:0; background:#31353D; color:#fff; height:48px; line-height:48px; padding:0 10px; text-align:center; width:143px;} 
.shopp .form-control span { color:#898989;} 
.shopp .lin-k img { margin:-5px 0px 0 0;}
.shopp .lin-k a {line-height: 14px; color:#464646; padding:0px 10px; margin-top:10px; display:inline-block; border-right:1px solid #464646; }
.navbar-brand {   height: auto;
    margin-top: 0;
    padding-bottom: 10px;
    padding-top: 5px;}
.f-nav { background:#31353D; margin-top:0px;}
.f-nav ul li a{ font-size:22px; color:#898989; padding:25px 20px 15px;  min-height: 80px;  text-align: center; font-weight:500;}
.f-nav ul li a .caret { margin:0;}
.f-nav .dropdown-menu li a { padding:6px 10px; text-align:left;  min-height: 10px;}
.f-nav .dropdown-menu {background:#31353D; padding:0; border-top:none;}
.f-nav .dropdown-menu li a:hover { color:#fff; background:#d83138;}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
	color:#fff; background:#d83138;
	}
.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>.active {
		color:#fff; background:#d83138;
}
.f-nav {}
.pad-n { padding-left:0; padding-right:0;}
.gib-son { background:#D83138; color:#fff; margin-top: 1px;
    min-height: 547px; padding:0 30px;}
.gib-son h1 { font-size:60px;}
.gib-son h1 span{ font-size:30px; color:#31353D; display:block; font-family: 'rockwell_lightregular';}
.gib-son p{color:#31353D; font-size:20px; line-height:20px; margin:50px 0; display:block; font-family: 'rockwell_lightregular';}
.gib-son h2 { font-size:28px; font-family: 'rockwell_lightregular';}
.gib-son h2 span{ font-size:46px; display:block;}
.gib-son .input-group { margin-top:40px;}
.gib-son .input-group-addon{ border:none; border-radius:0; background:#000; height:56px; width:60px; text-align:center;} 
.gib-son .form-control { border:none; border-radius:0; background:#fff; color:#D83138; height:56px; line-height:56px; font-size:22px; padding:0 10px; text-align:center; width:155px;} 
.gib-son .form-control span { color:#898989;} 

.serc { background:#000000; color:#fff; overflow:hidden; padding:20px 0 40px;}
.serc h2 { font-size: 36px; text-transform:uppercase; margin-left:20px;}
.serc .input-group { border:2px solid #fff; border-radius:2px; margin-top:15px;}
.serc .input-group .form-control { border-radius:0px; border:none; height:50px;  font-family: 'rockwell_lightregular'; font-size:21px; }


.order-onl9 { background:#d83138; color:#fff; text-transform:uppercase; overflow:hidden; margin-top:10px; margin-bottom:10px; min-height:170px; margin-right:-5px;}
.order-onl9 img { float:left; margin:40px 60px 40px 40px}
.order-onl9 h1 { font-size:40px; margin-top:40px}
.order-onl9 h1 span { font-size:30px; display:block;}

.free-del { background:#000; margin-left:-5px; margin-right:0px;}
.free-del img { float:left; margin:60px 60px 40px 40px}

.heading { font-size:36px; text-align:center; color:#131418; margin:55px 10px 30px ;}
.heading .bg-fff { background:#fff; position:relative; z-index:2; padding:0 15px; }
.red {color:#d83138;}
.heading .bor { border-bottom:2px solid #898989; width:100%; display:block; position:relative; margin-top:-25px;}

.f-pro { background:#dbd7d7; text-align:center; padding:4px 4px 0; border-bottom:5px solid #31353d;}
.f-pro p { font-size:18px; color:#363636;}
.f-pro h3 {font-size:26px; color:#d83138; font-weight:700;}
.f-pro .backe-img img { width:100%;}

.f-pro .input-group { width:141px; margin:15px auto;}
.f-pro .input-group-addon{ border:none; border-radius:0; background:#000; height:33px; width:25px; text-align:center;} 
.f-pro .form-control { border:none; border-radius:0; background:#D83138; color:#fff; height:33px; text-transform:uppercase; line-height:33px; font-size:14px; padding:0 5px; text-align:center; width:97px;} 
.f-pro .form-control span { color:#898989;} 
.f-pro .wish-l { color:#757373; font-size:14px; font-family:Georgia, "Times New Roman", Times, serif; margin-bottom:10px; display:inline-block;}
.pro-slid .owl-nav { width:100%; height:44px;  position:absolute; top:50%; margin-top:-22px;}
.pro-slid .owl-next, .pro-slid .owl-prev { width:34px; height:44px; font-size:0;}
.pro-slid .owl-next { float:right; background:url("//cdn.shopify.com/s/files/1/0985/6032/t/2/assetspr-r.png") left top no-repeat;}
.pro-slid .owl-prev { float:left; background:url("//cdn.shopify.com/s/files/1/0985/6032/t/2/assets/pr-l.png") left top no-repeat;}

.sid-bar {border:1px solid #dcdcdc; margin-top:40px; background:#ececec;  min-height: 605px;}
.sid-bar .heading { 
  background: #d83138 none repeat scroll 0 0;
    color: #fff;
    font-size: 24px;
    margin: 0;
    padding: 8px 12px;
    text-align: left;
}
.sid-bar .heading span { color:#31353d;}
.sid-bar .list-group-item-success i { margin-top:3px; float:right; font-size:11px;}
.sid-bar .list-group-item-success { background:#ececec; font-size:16px; color:#515151; border:none; border-bottom:1px dashed #e1e1e1; margin-bottom:0;}
.sid-bar .list-group-item:last-child {background:#ececec; font-size:16px; color:#515151; border:none;}
.sid-bar .list-group-item-success:hover, .sid-bar .list-group-item-success:focus { background:#ececec; color:#000;}
.sid-bar .list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.sid-bar .panel { border:none; padding:0 10px; background:#ececec; box-shadow:none;}

.m-pro { padding-left:0; margin-top:40px;}
.m-pro .heading { background:#000; color:#fff; text-align:left; width:auto; padding:0 15px; margin:0 0px 0 10px;}
.m-pro .top .set { float:left; margin:20px 0;}
.m-pro .top .sort p { float:left; margin-right:10px;}
.select { width:140px; height:26px; line-height:26px; float:left; overflow:hidden; border:1px solid #dddddd; background:url(https://cdn.shopify.com/s/files/1/0985/6032/t/2/assets/select.jpg?18417592854225942850) right center no-repeat !important;}
.select select {padding: 0; background:none; border:none; width:125%;  height:26px; line-height:26px; color:#ababab;}

.m-pro .top { margin-left:10px;}
.m-pro .top .pagin p {color:#000; margin-left:50px; float:left;}
.m-pro .top .pagin p span {color:#898989; padding:0 10px;}
.m-pro .top .pagin nav { float:left; width:auto;}
.m-pro .top .pagin nav .pagination { margin:0;}
.m-pro .top .pagin .pagination > li > a, .pagination > li > span {
	 color:#000;
	 padding: 5px 10px !important;
	 border:none;
	 font-size:14px;
	}

.m-pro .top .pagin .pagination > li > a:hover, .m-pro .top .pagin .pagination > li > span:hover, .m-pro .top .pagin .pagination > li > a:focus, .m-pro .top .pagin .pagination > li > span:focus {
	background:#d83138;
	color:#fff;
	}
.m-pro .f-pro { margin-bottom:45px; background:#fff;}
.pro-l .f-pro {background:#dbd7d7;}
.pro-l .heading { margin-bottom:45px;}
.ab-t .heading { margin-left:0;}
.in-ban { padding:0 10px;}
.f-form label { margin-top:15px;}
.f-form .form-control {border-radius:0px; border:none; border-bottom:1px solid #CCC; box-shadow:none; font-size:16px; }
.btn-primary {
	border-radius:0px !important; border:none !important;  height:40px !important; background:#d83138 !important; border-right:1px solid #e2979a !important; color:#fff; padding: 6px 15px; font-size:20px; text-transform:uppercase;
	margin:20px 0;
	}
.update-cart {
		border-radius:0px !important; border:none !important;  height:40px !important; background:#000 !important; border-right:1px solid #000 !important; color:#fff; padding: 6px 15px; font-size:20px; text-transform:uppercase;
	margin:20px 0;
	}
.subscribe { background:url(//cdn.shopify.com/s/files/1/0985/6032/t/2/assets/bas.jpg?9513611171430261969) left center no-repeat #000; color:#fff; overflow:hidden; min-height:194px; !important}
.subscribe .input-group { border:0px solid #fff; border-radius:0px; margin-top:15px; background:none;}
.subscribe .form-control {text-transform: inherit !important; float:left; max-width:405px; border-radius:0px; margin-right:10px; border:none; height:40px;  font-family:Georgia, "Times New Roman", Times, serif; font-size:20px; }
.subscribe .input-group-btn .btn-default { border-radius:0px; border:none; border:none;  height:40px; background:#d83138; border-right:1px solid #e2979a; color:#fff; padding: 6px 15px; font-size:20px; text-transform:uppercase;}
.subscribe h1 { font-size:30px; text-transform:uppercase; margin-bottom:40px; margin-top:30px;color: #fff !important;}


.blog h1 { font-size:36px; color:#000; border-bottom:1px dashed #c9c9c9; padding:15px;}

.blog .set { border-bottom:1px dashed #c9c9c9; padding:15px 0; overflow:hidden; margin-top:15px;}
.pro-l .blog .set:last-child { border:none;}
.blog .img { padding:3px; float:left; border:1px solid #ebebeb;}
.test .img { border:none;}
.blog .content { padding:0 20px; float:left;   max-width: 72%;}
.blog .content .top { overflow:hidden; display:block;}
.blog .content .top .st-in { float:left; padding-right:15px; color:#838383;}
.blog .content .top .st-in i { color:#6b6b6b;}
.blog .content h6 { color:#595959; font-size:14px;}
.blog .content p {color:#979696; font-size:14px; }
.pro-l .blog .content p, .pro-l .blog .content h6 {font-size:16px; }
.testimonial { min-height:531px; width:100%; }
.shad {background:url(https://cdn.shopify.com/s/files/1/0985/6032/t/2/assets/shadow.jpg) left 30px no-repeat; padding-left:70px;}
.testimonial h1 { font-size:36px; color:#000; border-bottom:1px dashed #c9c9c9; padding:15px;}
.testimonial .content { background:url(https://cdn.shopify.com/s/files/1/0985/6032/t/2/assets/for-test.jpg) center center no-repeat; height:219px; font-size:14px; color:#6e6e6e;  max-width: 399px;
    padding: 55px 25px 50px; background-size:100%;}
.testimonial .test-writer img { float:right; padding:2px; border-radius:50%; border:1px solid #ececec;}
.testimonial .test-writer p { color:#d83138; font-size:16px; float:right;  margin: 51px 10px 0 0;}

.map { margin:15px 0;}
.map iframe { border:none;}



.footer { border-top:40px solid #d83138; font-size:14px; color:#54575d; padding-bottom:55px; background:#000;}
.footer h3 { font-size:20px; color:#d83138; margin:30px 0 30px;}
.footer ul li { font-size:14px; list-style:none;}
.footer ul { padding:0; margin:0px 0 0 0;}
.footer a { color:#54575d; font-size:14px;}
.footer .form-control { max-width:250px; border-radius:0px; margin-right:10px; border:none; height:34px;  font-size:14px; }
.footer .btn-default {color: #fff !important; border-radius:0px; border:none; border:none; margin-top:10px;  height:34px; background:#d83138; border-right:1px solid #e2979a; color:#fff; padding: 4px 15px; font-size:20px; text-transform:uppercase;}
.footer ul li a { margin-bottom:10px; display:block;}
.footer .social a { margin-top:10px; float:left; display:block; margin-right:10px;}


.footer .col-sm-6:last-child { padding-left:50px;}
.pad-l-50 {padding-left:50px;}
.pd-t-35 { padding-top:35px;}



.b-bar { background:#31353d; height:50px; line-height:50px;}
.b-bar .copy { float:right; color:#d83138;}
.b-bar .navy { float:left;}
.b-bar .navy a { font-size:16px; color:#63656a; border-right:1px solid #63656a; float:left; line-height:15px; padding:0 10px; margin-top:11px;}
.b-bar .navy a:last-child { border:none;}
.b-bar .navy a:first-child { padding-left:0;}
.shopp .lin-k a:last-child { border:none;}
.carousel-indicators {  left:auto; right:20px;}
.carousel-inner img { width:100%;}

.carousel-indicators li { background:#fff;}
.carousel-indicators .active { background:#d83138; width:10px; height:10px; border-color:#d83138; margin:1px;}
.carousel-caption {
    left: auto;
    padding: 0;
	text-align:left;
	text-shadow:none;
	box-shadow:none;
	bottom:0;
	top:0;
	height:100%;
	width:312px;
    right: 0;
}
.ajaxcart__qty-adjust { color:#d83138 !important;}
.ajaxcart__qty-adjust:hover, .ajaxcart__qty-adjust:focus {color:#fff !important; background:#d83138 !important;}
.pro-detail h1 { font-size:50px; margin-top:0; color:#d83138;}
.pro-detail h1 span { font-size:30px; margin-top:0; color:#333; display:block; margin-top:-10px;}
.pro-detail h2	{ font-size:36px; margin-top:0; color:#000;}

.pro-detail { padding-left:50px;}
.thumbs a img {max-width:100px; border:1px solid #000;}
.thumbs .active { opacity:0.5;}
.bx-controls .bx-default-pager { display:none !important;}

.fieldset {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 1.5em;
}
.field::after, .field::before {
    content: "";
    display: table;
}
.field::after {
    clear: both;
}
.field::after, .field::before {
    content: "";
    display: table;
}

.fieldset input[type="text"], .fieldset input[type="tel"], .fieldset input[type="email"], .fieldset input[type="password"], .fieldset select, .fieldset .select-group {
    float: left;
    width: 65%;
	 padding: 1.25em 10px;
	   background: transparent none repeat scroll 0 0;
    border: medium none;
	margin-bottom:0;
}
.select-group {
    position: relative;
}
.select-group::after {
    background: #e5e5e5 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 24px;
    left: 50%;
    margin-left: -0.5px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 1px;
}
.fieldset select {
    float: left;
    width: 63%;
}
.fieldset select {
    -moz-appearance: none;
    background-image: url(https://cdn.shopify.com/s/files/1/0985/6032/t/2/assets/c-select.jpg), none !important;
    background-position: right center;
    background-repeat: no-repeat;
}
.boxsizing select {
    box-sizing: border-box;
    padding-right: 2em;
}
.select-group select:first-child {
    float: left;
}
.field-group--has-subfields {
    background-color: #fafafa;
}
.field--blank-slate {
    padding: 2em;
    text-align: center;
}
.field label {
    float: left;
    width: 35%;
	 padding: 1.25em 10px;
	 margin-bottom:0;
}
.boxshadow .field--focus {
    border-color: transparent;
    border-radius: 4px !important;
    box-shadow: 0 0 0 2px #2a9dcc;
    z-index: 99;
}

.order-summary {
    background: #fafafa none repeat scroll 0 0;
    border-top: 1px solid #e1e1e1;
    color: #777;
	border: 1px solid #dbdbdb;
    border-radius: 4px;
}
.order-header { border-bottom: 1px solid #e1e1e1;   padding: 1.5em;}
.order-header h3 { margin:0;}
.order-summary .set { overflow:hidden; width:100%;  border-bottom: 1px solid #e1e1e1; }
.order-summary .set:last-child { border-bottom:none;}


.order-summary .pric {padding: 1.5em; float:right;}
.order-summary .produ {padding: 1.5em; float:left;}
.order-summary .produ h4 { color:#555; font-size:16px; font-weight:normal;}
.order-summary .pric {
  color: #555;
    font-weight: 500;
}	
.order-summary .total .produ {padding:0.3em  1.5em;}
.order-summary .total .produ h4 { margin:0; font-size:14px; line-height:30px; font-weight:normal;}

.order-summary .total .pric {padding:0.3em  1.5em;}
.order-summary .total .pric h4 { margin:0; font-weight:normal; line-height:30px;}

.order-summary .total .pric h2 {line-height:30px; color:#d83138;}
.order-summary .total .pric h2 span {
    background: #999 none repeat scroll 0 0;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    float: left;
    font-size: 14px;
    line-height: 17px;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 3px;
}

.order-summary .due .produ h4 { margin:0; font-weight:normal; line-height: 65px;}


.cart-out { overflow:hidden;}
@media (max-width:390px) {
.order-summary .pric {padding: 0.5em; float:right;}
.order-summary .produ {padding: 0.5em; float:left;}
.order-summary .produ h4 { color:#555; font-size:13px; }
.order-summary .pric { font-size:13px; }
.order-summary .total .pric h2 { font-size:20px;}

	}









@media (min-width:768px) and (max-width:1024px) {
	.carousel-caption {
		width:100%;
		bottom:0;
		top:auto;
		min-height:100px;
		position:relative;
		}
	.shopp .lin-k a {
    padding: 0 5px;
}
	.call .image {
    margin: 15px 5px;
}
.call i {
     margin: 3px 10px 0 12px;
}
.f-nav ul li a {
    padding: 30px 11px 10px;
    }
.shopp {
    margin-left: 10px;
	}
	.subscribe .form-control {
		max-width: 269px;
		}
		.subscribe h1 {
    font-size: 26px;}
	.testimonial h1, .blog h1 { font-size:26px;}
	.blog .img {
    width:25%;
}
.blog .img img{
    max-width:100%;
}
.blog .content {
    float: left;
    max-width: 75%;
    padding: 0 10px;
}
.blog .content .top .st-in { font-size:13px; padding-right: 6px;}
.shad {
    background: rgba(0, 0, 0, 0) url("https://cdn.shopify.com/s/files/1/0985/6032/t/2/assets/shadow.jpg") no-repeat scroll 0 30px;
    padding-left: 60px;
}
.footer .bor {
    border-left: medium none;
    border-right: medium none;
    min-height: 160px;
    padding: 0;
}
.footer .col-sm-6:last-child { padding-left:10px;}
.pad-l-50 {
    padding-left: 0px;
}
.gib-son h1 {
    float: left;
    font-size: 40px;
    margin-right: 20px;
}
.gib-son h1 span {
    font-size: 22px;
}
.gib-son h2 {
    float: left;
    font-size: 22px;
	margin-right:20px;
}
.gib-son h2 span {font-size: 40px;}
.gib-son .input-group {
    margin-top: 52px;
}
.gib-son p { margin:30px 0;}
.gib-son { min-height:100px;}
.order-onl9 h1 {
    font-size: 30px;
    margin-top: 30px;
}
.order-onl9 h1 span {
    display: block;
    font-size: 20px;
}
.free-del img, .order-onl9 img {
    float: left;
    margin: 40px 40px 40px 30px;
}
.carousel-indicators {
    bottom: 200px;
	width:auto;
}
}
@media (max-width:767px) {
	.cart-out .btn-primary { width:100%; margin-bottom:10px;}
	.pro-detail h1 { font-size:36px; margin-top:0; color:#d83138;}
	.pro-detail h1 span { font-size:20px; margin-top:0; color:#d83138;}
.pro-detail h2	{ font-size:26px; margin-top:0; color:#000;}
.pro-detail {
    padding-left: 10px;
}
	.carousel-caption {
		width:100%;
		bottom:0;
		top:auto;
		min-height:100px;
		position:relative;
		}
			.subscribe h1 {
    font-size: 26px;}
	.testimonial h1, .blog h1 { font-size:26px;}
.gib-son h1 {

    font-size: 30px;

}
.gib-son h1 span {
    font-size: 18px;
}
.gib-son h2 {
    float: left;
    font-size: 18px;

}
.gib-son h2 span {font-size: 30px;}
.gib-son .input-group {
    margin-top: 40px;
}
.gib-son p { margin:15px 0;}
.gib-son { min-height:100px; padding:20px 15px;}
.order-onl9 img, .free-del img {
    float: left;
    margin: 20px 10px;
}
.free-del { margin-left:0;}
.order-onl9 h1 {
    font-size: 28px;
    margin-top: 12px;
}
.order-onl9 h1 span {
    display: block;
    font-size: 18px;
}
.order-onl9 { min-height:1px;   margin-right: 0px;}
.heading { font-size:30px;}
.m-pro .top .pagin p { margin-left:0;}
.m-pro .top .set { margin:10px 0;}
.subscribe .input-group-btn { text-align:center; padding:10px 0;}
.subscribe { background-size:100%;}
	.blog .img {
    width:auto;
	margin:0px auto 10px;
	text-align:center;
}
.blog .img img{
    max-width:100%;
}
.blog .content {
    float: left;
	
    max-width: 100%;
    padding: 0 10px;
}
.shad { background:none; padding-left:10px;}
.footer .bor {
    border-left: medium none;
    border-right: medium none;
    min-height: 160px;
    padding: 0;
}
.footer .col-sm-6:last-child { padding-left:10px;}
.pad-l-50 {
    padding-left: 0px;
}
.b-bar { height:auto; line-height:20px;}
.b-bar .navy a {
    
    font-size: 14px;
    margin-top: 17px;
    padding: 0 8px;
}
.b-bar .copy { float:left; font-size: 14px; margin-top:15px;}
.navbar-brand > img { max-width:100%;}
.navbar-brand { max-width:250px; margin-top:0;}
.f-nav ul li { width:100%;	border-bottom:1px solid #898989; overflow:hidden;  min-height: 65px;}
.f-nav ul li:last-child { border:none;}
.f-nav ul li ul li { min-height:1px; border:none;}
.f-nav ul li a {
    padding: 10px;
	float:left;
	display:block;
	width:100%;
	min-height: 65px;
	text-align:left;

    }
.f-nav ul li a .caret {
    float: left;
    margin: 0 0 0 5px;
}
.carousel-indicators {
    left: auto;
	width:auto;
	bottom: auto;
	top:45%;
    right: 20px;
}
	}
@media (max-width:550px) {.carousel-indicators {top:35%;}}

@media (max-width:400px) {.carousel-indicators {top:23%;}}

@media (max-width:767px) {}

@media (max-width:767px) {}

